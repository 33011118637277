
import { Component, Vue } from 'nuxt-property-decorator';
import SideBar from '~/components/login-register/SideBar.vue';
import {
  metatags,
  createTitleDescriptionMetatags,
  createPropertyMetatag,
  MetatagsPropertiesEnum
} from '~/utils/constants';
@Component({
  components: {
    SideBar
  },
  layout: 'empty',
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    const locale = this.$store.state.i18n.locale;

    return {
      title: 'IREEN - Login',
      meta: [
        ...(locale === 'en' ? [metatags.NOINDEX] : i18nHead.meta),
        ...createTitleDescriptionMetatags(
          'IREEN - LOGIN',
          'Kostenfreie Funktionen - IREEN-Login : Wertbeobachtung, Vergleichsobjekte, Infrastruktur in Objektumgebung ► Jetzt testen!'
        ),
        createPropertyMetatag(
          MetatagsPropertiesEnum.OgUrl,
          'https://ireen.com/login'
        ),
        createPropertyMetatag(MetatagsPropertiesEnum.OgLocale, locale)
      ]
    };
  }
})
export default class Login extends Vue {
  fetch({ from, store }: any) {
    if (from) {
      store.commit('userProfile/setRedirectTo', from.path);
    }
  }
}
