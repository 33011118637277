import { inject, singleton } from 'tsyringe';
import { Store } from 'vuex';
import { NuxtFireInstance } from '@nuxtjs/firebase';
import { ApiService } from '~/services/api.service';

@singleton()
export class AuthService {
  private $fire: NuxtFireInstance;

  constructor(
    private readonly apiService: ApiService,
    @inject('store') private readonly store: Store<any>
  ) {
    this.$fire = this.store.$fire;
  }

  get auth() {
    return this.$fire.auth;
  }

  async deleteAccount() {
    await this.apiService.delete('/admin/delete-user');
  }

  async syncUser(
    evaluationId: string,
    uid: string,
    previousToken: string
  ) {
    await this.apiService.post(`/evaluations/${evaluationId}/sync-user`, {
      uid
    }, {
      headers: {
        Authorization: `Bearer ${previousToken}`
      }
    });
  }
}
