import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=08d039a0&"
import script from "./SideBar.vue?vue&type=script&lang=ts&"
export * from "./SideBar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Login: require('/home/cgr/projects/IREEN/frontend/ireen-frontent/components/login-register/login/Login.vue').default})
