export const metatags = {
  NOINDEX: {
    hid: 'robots',
    name: 'robots',
    content: 'noindex'
  }
};

export enum MetatagsPropertiesEnum {
  Description = 'description',
  OgTitle = 'og:title',
  OgDescription = 'og:description',
  OgUrl = 'og:url',
  OgLocale = 'og:locale',
  OgSiteName = 'og:site_name'
}

export const createPropertyMetatag = (
  property: string,
  content: string
): any => ({
  hid: property,
  property,
  content
});

export const createNameMetatag = (name: string, content: string): any => ({
  hid: name,
  name,
  content
});

export const createTitleDescriptionMetatags = (
  title: string,
  description: string
): any[] => [
  createNameMetatag(MetatagsPropertiesEnum.Description, description),
  createPropertyMetatag(MetatagsPropertiesEnum.OgTitle, title),
  createPropertyMetatag(MetatagsPropertiesEnum.OgDescription, description)
];
