
import { isEmpty as _isEmpty } from 'lodash';
import { Component, mixins, Prop, Vue } from 'nuxt-property-decorator';
import VueI18n from 'vue-i18n';
import { validationMixin } from 'vuelidate';
import Toast from '~/components/toast/Toast.vue';
import AlertTypeEnum from '~/components/toast/toastEnum';
import { Store } from '~/decorators';
import UserProfile, { NotificationFrequencyEnum } from '~/store/userProfile';

@Component({
  components: {
    Toast
  }
})
export default class Register extends mixins(validationMixin) {
  @Store() userProfile!: UserProfile;
  @Prop() lang!: string;

  isRegistering = false;
  agreedTermsPolicy = false;
  passwordType = 'password';
  registered = false;
  firstName = '';
  lastName = '';
  password = '';

  get email() {
    return this.$store.state.loginRegister.email;
  }

  set email(val: string) {
    this.$store.commit('loginRegister/SET_EMAIL', val);
  }

  get shouldDisable() {
    return (
      this.isRegistering ||
      !this.email ||
      !this._isValidEmail(this.email) ||
      !this.password ||
      !this._isValidPassword(this.password) ||
      !this.firstName ||
      !this.lastName ||
      !this.agreedTermsPolicy
    );
  }

  get firstNameRules(): ((v: string) => true | VueI18n.TranslateResult)[] {
    return [(v: string) => !!v || this.$i18n.t('register.errors.firstname')];
  }

  get lastNameRules(): ((v: string) => true | VueI18n.TranslateResult)[] {
    return [(v: string) => !!v || this.$i18n.t('register.errors.lastname')];
  }

  get emailRules(): ((v: string) => true | VueI18n.TranslateResult)[] {
    return [
      (v: string) => !!v || this.$i18n.t('register.errors.email'),
      (v: string) =>
        this._isValidEmail(v) || this.$i18n.t('register.errors.invalidEmail')
    ];
  }

  get passwordRules(): ((v: string) => true | VueI18n.TranslateResult)[] {
    return [
      (v: string) => !!v || this.$i18n.t('register.errors.password'),
      (v: string) =>
        this._isValidPassword(v) ||
        this.$i18n.t('register.errors.invalidPassword')
    ];
  }

  togglePassword(): void {
    this.passwordType === 'password'
      ? (this.passwordType = 'text')
      : (this.passwordType = 'password');
  }

  register(): void {
    this.isRegistering = true;
    const isValid = (
      this.$refs.form as Vue & { validate: () => boolean }
    ).validate();
    console.log('isValid', isValid);
    if (isValid) {
      this._createUser();
    }
  }

  async registerWithGoogle(): Promise<void> {
    this.isRegistering = true;
    const googleAuthProvider = new this.$fireModule.auth.GoogleAuthProvider();
    googleAuthProvider.addScope('email');

    try {
      const userCredential = await this.$fire.auth.currentUser!.linkWithPopup(
        googleAuthProvider
      );
      const user = userCredential.user;
      const userRef = await this.$fire.firestore
        .collection('users')
        .doc(user?.uid)
        .get();
      const existingUser = await this.$fire.firestore
        .collection('users')
        .where('email', '==', user?.email)
        .get();

      if (!userRef.exists && _isEmpty(existingUser?.docs || [])) {
        await user?.sendEmailVerification();
        await user?.sendEmailVerification();
        const displayName = user?.displayName?.split(' ') || [];
        const firstName = displayName[0] || '';
        const lastName = displayName[1] || '';

        await this.$fire.firestore.doc(`users/${user?.uid}`).set({
          uid: user?.uid,
          email: user?.email,
          firstName,
          lastName,
          emailVerified: user?.emailVerified,
          claims: null,
          avatar:
            'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Clipart.png',
          zip: null,
          city: null,
          notificationSettings: {
            frequency: NotificationFrequencyEnum.Daily,
            filters: [],
            inBrowser: true,
            viaEmail: true
          }
        });
        this._logIn(user);
      } else {
        this._logIn(user);
      }
    } catch (e: any) {
      this.isRegistering = false;
      this.$store.commit('toast/showToast', {
        alertType: AlertTypeEnum.Error,
        message: e.message,
        timeout: 3000
      });
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  private _goToDashboard(): void {
    if (this.$store.state.userProfile.redirectTo === '/at/kaufen') {
      this.$router.push(this.$store.state.userProfile.redirectTo);
    } else {
      this.$router.push(`/${this.lang}/dashboard`);
    }
  }

  async registerWithFacebook(): Promise<void> {}

  created(): void {
    if (this.userProfile?.user?.email) {
      this._goToDashboard();
    }
  }

  goToHome() {
    this.$router.push(`/${this.lang}`);
  }

  goToLoginPage(): void {
    this.$router.push(`/${this.lang}/login`);
  }

  private _isValidPassword(pw: string): boolean {
    return /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(pw);
  }

  private _isValidEmail(email: string): boolean {
    return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  private async _createUser(): Promise<void> {
    try {
      const userCredential = this.$fireModule.auth.EmailAuthProvider.credential(
        this.email,
        this.password
      );
      const newUserCredential =
        await this.$fire.auth.currentUser?.linkWithCredential(userCredential);
      if (newUserCredential) {
        await newUserCredential!.user?.sendEmailVerification();
        await this.$fire.firestore
          .doc(`users/${newUserCredential!.user?.uid}`)
          .set({
            uid: newUserCredential!.user?.uid,
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            emailVerified: newUserCredential.user?.emailVerified,
            claims: null,
            avatar:
              'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Clipart.png',
            zip: null,
            city: null,
            notificationSettings: {
              frequency: NotificationFrequencyEnum.Daily,
              filters: [],
              inBrowser: true,
              viaEmail: true
            }
          });
        this._logIn(newUserCredential.user);
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);

      let message = e.message;
      this.isRegistering = false;

      if (e.code === 'auth/email-already-in-use') {
        message = this.$i18n.t('register.errors.emailInUse').toString();
      }

      this.$store.commit('toast/showToast', {
        alertType: AlertTypeEnum.Error,
        message,
        timeout: 3000
      });
    }
  }

  private async _logIn(userCredential: any) {
    const userRef = await this.$fire.firestore
      .collection('users')
      .doc(userCredential.uid)
      .get();
    const accessToken = await userCredential.getIdTokenResult();

    this.$store.commit('userProfile/SET_PROFILE', userRef.data());
    this.$store.commit('userProfile/SET_USER', {
      uid: userCredential.uid,
      email: userCredential.email,
      emailVerified: userCredential.emailVerified,
      claims: accessToken?.claims,
      accessToken: accessToken.token,
      stayLoggedIn: true
    });
    this.$store.commit(
      'userProfile/SET_NOTIFICATION_SETTINGS',
      userRef.data()?.notificationSettings
    );
    this.registered = true;
    this.isRegistering = false;
  }
}
