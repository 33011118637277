
import { isEmpty as _isEmpty } from 'lodash';
import { Component, mixins, Prop } from 'nuxt-property-decorator';
import VueI18n from 'vue-i18n';
import { validationMixin } from 'vuelidate';
import Toast from '~/components/toast/Toast.vue';
import AlertTypeEnum from '~/components/toast/toastEnum';
import { Service, Store } from '~/decorators';
import EvaluationsStore from '~/store/evaluations';
import { NotificationFrequencyEnum } from '~/store/userProfile';
import { AuthService } from '~/services/auth.service';

@Component({
  components: {
    Toast
  }
})
export default class Login extends mixins(validationMixin) {
  @Store() evaluationsStore!: EvaluationsStore;
  @Service() authService!: AuthService;
  @Prop() lang!: string;
  password = '';
  stayLoggedIn = false;
  passwordType = 'password';
  isLoggingIn = false;

  get email() {
    return this.$store.state.loginRegister.email;
  }

  set email(val: string) {
    this.$store.commit('loginRegister/SET_EMAIL', val);
  }

  get shouldDisable() {
    return (
      this.isLoggingIn ||
      !this.email ||
      !this._isValidEmail(this.email) ||
      !this.password
    );
  }

  get emailRules(): ((v: string) => true | VueI18n.TranslateResult)[] {
    return [
      (v: string) => !!v || this.$i18n.t('register.errors.email'),
      (v: string) =>
        this._isValidEmail(v) || this.$i18n.t('register.errors.invalidEmail')
    ];
  }

  get passwordRules(): ((v: string) => true | VueI18n.TranslateResult)[] {
    return [(v: string) => !!v || this.$i18n.t('register.errors.password')];
  }

  changePassword() {
    this.passwordType === 'password'
      ? (this.passwordType = 'text')
      : (this.passwordType = 'password');
  }

  submit() {
    this.isLoggingIn = true;
    this._login();
  }

  goToReagisterPage() {
    this.$router.push(`/${this.lang}/register`);
  }

  mounted() {
    this.isLoggingIn = false;
  }

  async registerWithGoogle(): Promise<void> {
    this.isLoggingIn = true;

    const googleAuthProvider = new this.$fireModule.auth.GoogleAuthProvider();
    googleAuthProvider.addScope('email');

    try {
      const previousToken = await this.$fire.auth.currentUser?.getIdToken();
      const result = await this.$fire.auth.signInWithPopup(googleAuthProvider);
      const user = result?.user;

      if (this.evaluationsStore.hasSelectedEvaluation) {
        const id = this.evaluationsStore.selectedEvaluation!.id;
        const uid = user?.uid;
        if (uid && previousToken) {
          await this.authService.syncUser(id, uid, previousToken);
        }
      }

      if (user?.email) {
        this._finishLogin(user);
      }
    } catch (e: any) {
      this.isLoggingIn = false;
      this.$store.commit('toast/showToast', {
        alertType: AlertTypeEnum.Error,
        message: e.message,
        timeout: 5000
      });
      console.error(e);
    }
  }

  async registerWithFacebook(): Promise<void> {}

  private _goToDashboard(): void {
    if (this.$store.state.userProfile.redirectTo === '/at/kaufen') {
      this.$router.push(this.$store.state.userProfile.redirectTo);
    } else {
      this.$router.push(`/${this.lang}/dashboard`);
    }
  }

  private _goToHome(): void {
    this.$router.push(`/${this.lang}`);
  }

  private _afterRequest() {
    // if (this.evaluationsStore.hasEvaluations) {
    this._goToDashboard();
    // } else {
    //   this._goToHome();
    // }
  }

  private _isValidEmail(email: string): boolean {
    return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  private async _login() {
    try {
      const previousToken = await this.$fire.auth.currentUser?.getIdToken();
      const userCredential = await this.$fire.auth.signInWithEmailAndPassword(
        this.email,
        this.password
      );
      if (userCredential) {
        const userRef = await this.$fire.firestore
          .collection('users')
          .doc(userCredential!.user?.uid)
          .get();
        if (userRef.exists) {
          const accessToken = await userCredential.user?.getIdTokenResult();
          this.$store.commit('userProfile/SET_PROFILE', userRef.data());
          this.$store.commit('userProfile/SET_USER', {
            uid: userCredential!.user?.uid,
            email: this.email,
            emailVerified: userCredential.user?.emailVerified,
            claims: accessToken?.claims,
            accessToken: accessToken?.token,
            stayLoggedIn: this.stayLoggedIn
          });
          this.$store.commit(
            'userProfile/SET_NOTIFICATION_SETTINGS',
            userRef.data()?.notificationSettings
          );
          if (this.evaluationsStore.hasSelectedEvaluation) {
            const id = this.evaluationsStore.selectedEvaluation!.id;
            const uid = userCredential!.user?.uid;
            if (uid && previousToken) {
              await this.authService.syncUser(id, uid, previousToken);
            }
          }
          this._afterRequest();
        }
      }
    } catch (e: any) {
      let message = e?.message || 'Login error';
      this.isLoggingIn = false;

      if (e.code === 'auth/user-not-found') {
        message = this.$i18n.t('login.errors.userNotFound').toString();
      }
      this.$store.commit('toast/showToast', {
        alertType: AlertTypeEnum.Error,
        message,
        timeout: 5000
      });

      console.error(e);
    }
  }

  private async _finishLogin(userCredential: any) {
    let userRef = await this.$fire.firestore
      .collection('users')
      .doc(userCredential!.user?.uid)
      .get();

    const existingUser = await this.$fire.firestore
      .collection('users')
      .where('email', '==', userCredential?.email)
      .get();

    if (!userRef.exists && _isEmpty(existingUser?.docs || [])) {
      await userCredential?.sendEmailVerification();
      const displayName = userCredential.displayName.split(' ');
      const firstName = displayName[0] || '';
      const lastName = displayName[1] || '';

      await this.$fire.firestore.doc(`users/${userCredential.uid}`).set({
        uid: userCredential.uid,
        email: userCredential.email,
        firstName,
        lastName,
        emailVerified: userCredential.emailVerified,
        claims: null,
        avatar:
          'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Clipart.png',
        zip: null,
        city: null,
        notificationSettings: {
          frequency: NotificationFrequencyEnum.Daily,
          filters: [],
          inBrowser: true,
          viaEmail: true
        }
      });
    }
    userRef = await this.$fire.firestore
      .collection('users')
      .doc(userCredential.uid)
      .get();
    const accessToken = await userCredential.getIdTokenResult();
    const firestoreUser = userRef.data();

    this.$store.commit('userProfile/SET_PROFILE', userRef.data());
    this.$store.commit('userProfile/SET_USER', {
      uid: userCredential?.uid,
      email: userCredential?.email,
      emailVerified: userCredential.emailVerified,
      claims: accessToken?.claims,
      accessToken: accessToken.token,
      stayLoggedIn: this.stayLoggedIn
    });
    this.$store.commit(
      'userProfile/SET_NOTIFICATION_SETTINGS',
      firestoreUser?.notificationSettings
    );
    this._afterRequest();
  }
}
