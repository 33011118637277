
import { Component, Vue } from 'nuxt-property-decorator';
import AlertTypeEnum from '~/components/toast/toastEnum';
@Component
export default class Toast extends Vue {
  get show() {
    return this.$store.state.toast.show;
  }

  set show(val) {
    this.$store.commit('toast/closeToast', val);
  }

  get timeout() {
    return this.$store.state.toast.timeout;
  }

  get message() {
    return this.$store.state.toast.message;
  }

  get alertType() {
    return this.$store.state.toast.alertType;
  }

  get messageType() {
    switch (this.alertType) {
      case AlertTypeEnum.Success:
        return 'Success';
      case AlertTypeEnum.Error:
        return 'Error';
      case AlertTypeEnum.Warning:
        return 'Warning';
      case AlertTypeEnum.Info:
        return 'Info';
    }
  }

  get color() {
    switch (this.alertType) {
      case AlertTypeEnum.Success:
        return '#cbf4e1';
      case AlertTypeEnum.Error:
        return '#f7d6d6';
      case AlertTypeEnum.Warning:
        return '#ffc107';
      case AlertTypeEnum.Info:
        return '#00bcd4';
    }
  }

  get icon() {
    if (
      this.alertType === AlertTypeEnum.Success ||
      this.alertType === AlertTypeEnum.Info
    ) {
      return require('~/assets/img/toast/success.svg');
    } else {
      return require('~/assets/img/toast/error.svg');
    }
  }
}
