
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class Description extends Vue {
  @Prop() lang!: string;

  get slides() {
    return [
      {
        img:
          this.lang !== 'en'
            ? require('~/assets/img/login-register/de/firstItem/visual@2x.webp')
            : require('~/assets/img/login-register/en/firstItem/visual@2x.webp'),
        title: this.$i18n.t('login.slider.item1.title'),
        text: this.$i18n.t('login.slider.item1.text')
      },
      {
        img:
          this.lang !== 'en'
            ? require('~/assets/img/login-register/de/secondItem/img_infra@2x.webp')
            : require('~/assets/img/login-register/en/secondItem/img_infra@2x.webp'),
        title: this.$i18n.t('login.slider.item2.title'),
        text: this.$i18n.t('login.slider.item2.text')
      },
      {
        img:
          this.lang !== 'en'
            ? require('~/assets/img/login-register/de/third/img_compare@2x.webp')
            : require('~/assets/img/login-register/en/third/img_compare@2x.webp'),
        title: this.$i18n.t('login.slider.item3.title'),
        text: this.$i18n.t('login.slider.item3.text')
      }
    ];
  }
}
