
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import Description from './description/Description.vue';
import Login from './login/Login.vue';
import Register from './register/Register.vue';

@Component({
  components: {
    Description,
    Login,
    Register
  }
})
export default class SideBar extends Vue {
  @Prop() type!: any;

  getLang() {
    return this.$store.state.i18n.locale || '';
  }
}
